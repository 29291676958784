/* global chrome */
import React, { useRef, useState, useCallback, useMemo, useEffect } from 'react';
import { useImmer } from "use-immer";


import { Stage, Layer, Rect, Group, Line, Transformer, Text} from 'react-konva';
import { Paper, Button, Popover, Divider, MenuList, MenuItem, ListItemText, ListItemIcon, Typography, FormGroup, FormControlLabel, Skeleton } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SimpleContent from 'components/simpleContent';
import { Html, Portal } from 'react-konva-utils';
import Draggable from 'react-draggable';

import ContentCut from '@mui/icons-material/ContentCut';
import FormatColorTextIcon from '@mui/icons-material/FormatColorText';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Switch from '@mui/material/Switch';
import Cloud from '@mui/icons-material/Cloud';

import BeizerCurve from './beizerCurve';
import EditableLine from './editableLine';
import Textbox from './textbox';

import Extension from 'components/extension';

import crownImg from './crown.png'

import socket from 'socket';

const InfiniteCanvas = props => {
  const [scale, setScale] = useState(1);
  const [moveScale, setMoveScale] = useState(1);
  const [moveStagePosition, setMoveStagePosition] = useState({x: 0, y: 0});
  const [hoveredActivityId, setHoveredActivityId] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const scaleRef = useRef(1); // Initialize scale as a ref
  const wheelTimerRef = useRef(null);

  const [stagePosition, setStagePosition] = useState({ x: 0, y: 0 });
  const [stageCornerXY, setStageCornerXY] = useState({ topLeftX: 0, topLeftY: 0, bottomRightX: 0, bottomRightY: 0 });
  const [selectedObject, setSelectedObject] = useState(null);
  
  const stageRef = useRef();
  const [draggingRect, setDraggingRect] = useState(false);
  const [selectedShape, setSelectedShape] = useState(null);
  const [isPanEnabled, setIsPanEnabled] = useState(true);

  const [canvasInfo, updateCanvasInfo] = useImmer(null);
  const [activities, updateActivities] = useImmer([]);
  const [texts, updateTexts] = useImmer([]);
  const [lines, updateLines] = useImmer([]);

  const [selectedLine, setSelectedLine] = useState(null);
  const [isDrawing, setIsDrawing] = useState(false);

  const [anchorPosition, setAnchorPosition] = useState({x: 0, y: 0});
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [objectAnchorPosition, setObjectAnchorPosition] = useState({x: 0, y: 0});

  const [isObjectContextMenuOpen, setIsObjectContextMenuOpen] = useState(false);
  const [isCardContextMenuOpen, setIsCardContextMenuOpen] = useState(false);

  const [isTextboxOpen, setIsTextboxOpen] = useState(false)

  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(true);

  const [isSaving, setIsSaving] = useState(false);

  //let activity = useSelector(state => state.activity);
  const user = useSelector(state => state.user);
  const { root, extensionInstalled, appType, extensionId } = useSelector(state => state.api);
  const params = useParams();

  const handleCanvasSave = () => {
    //if(extensionInstalled){
      setIsSaving(true)
      
      //chrome.runtime.sendMessage(extensionId, {type: 'FULL_SCREENSHOT'}, function(response) {
        
        fetch(`${root}/api/canvas/save`, 
          { 
            method: 'post', 
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({canvasId: canvasInfo._id, accessRestriction: canvasInfo.accessRestriction, scale, stagePosition, texts, activities}), 
            credentials: 'include' 
          }
        )
          .then(res => {
            //triggar a function after 0.5 seconds
            setTimeout(() => setIsSaving(false), 500)
          
            res.json()
          })
      //})
    //}
  };

  const handleSwitchChange = e => {
    console.log('handleswitch')
    let current;
    if(canvasInfo.accessRestriction == 'PUBLIC'){
      current = 'PRIVATE';
    } else {
      current = 'PUBLIC';
    }
    updateCanvasInfo(draft => {
      draft.accessRestriction = current;
    })
  };

  const handleDraggableStop = (e, data, a) => {
    console.log('handleDraggableStop', data);
    updateActivities(draft => {
      const foundIndex = draft.findIndex(d => d._id === a._id);
      if (foundIndex !== -1) {
        // Modify the object at the found index
        draft[foundIndex].x = draft[foundIndex].x + data.x;
        draft[foundIndex].y = draft[foundIndex].y + data.y;
        console.log('new x', a.initialX + data.lastX)
        console.log('new y', a.initialY + data.lastY)
      }
      return draft;
    });
  };

  const handleObjectDragEnd = (e, o) => {
    console.log('handleobjectdragend', e.target.x(), e.target.y())
    switch (o.type) {
      case 'TEXT': {
        updateTexts(draft => {
          const foundIndex = draft.findIndex(d => d.id === o.id);
          if (foundIndex !== -1) {
            // Modify the object at the found index
            draft[foundIndex].x = e.target.x();
            draft[foundIndex].y = e.target.y();
          }
          return draft;
        });
        break;
      }

      case 'COMMENT': {
        const foundIndex = activities.findIndex(d => d._id === o._id);
        socket.checkAndEmit('MOVE_ACTIVITY', { canvasId: canvasInfo?._id, activityId: o._id, x: e.target.x(), y: e.target.y() });
        updateActivities(draft => {
          if (foundIndex !== -1) {
            // Modify the object at the found index
            draft[foundIndex].x = e.target.x();
            draft[foundIndex].y = e.target.y();
          }
          return draft;
        });
        break; 
      }
    
      default:
    }
  };



  const handleObjectClick = (e) => {
    console.log(e.target)
    setSelectedShape(e.target);
  };

  const handleStageClick = (e) => {
    setIsContextMenuOpen(false);
    setIsObjectContextMenuOpen(false);

    const stage = e.target.getStage();
    const stageScale = stage.scaleX();
    const stagePosition = stage.position();
    const pointerPosition = stage.getPointerPosition();
    
    // Calculate the position relative to the stage (considering scale and position)
    const relativePosition = {
      x: (pointerPosition.x - stagePosition.x) / stageScale,
      y: (pointerPosition.y - stagePosition.y) / stageScale
    };
    
    closeAllContextMenu();
  };

  const calculatePosition = e => {
    //const stage = e.target.getStage();
    const stage = stageRef.current;
    const stageScale = stage.scaleX();
    const stagePosition = stage.position();
    const pointerPosition = stage.getPointerPosition();
    
    // Calculate the position relative to the stage (considering scale and position)
    const relativePosition = {
      x: (pointerPosition.x - stagePosition.x) / stageScale,
      y: (pointerPosition.y - stagePosition.y) / stageScale
    };
    return relativePosition;
  }

  const handleStageDragEnd = () => {
    // Get the current position of the stage
    const stage = stageRef.current;
    const stagePos = stage.position();
    console.log('current stage position', stagePos)
    
    // Update the state with the new position
    setStagePosition({ x: stagePos.x, y: stagePos.y });
  };


  let wheelTimer;

const handleWheel = e => {
  // e.preventDefault();
  e.stopPropagation();
  if(props.canvasData&&!e.shiftKey){
    return;
  }


  const scaleBy = 1.05;
  const stage = stageRef.current;
  const oldScale = scale;
  const pointerX = e.clientX;
  const pointerY = e.clientY;

  // Calculate the position of the stage relative to the viewport
  const stageBox = stage.container().getBoundingClientRect();
  const offsetX = pointerX - stageBox.left;
  const offsetY = pointerY - stageBox.top;

  const isShiftPressed = e.shiftKey;
  const isCmdPressed = e.metaKey||e.altKey;

  if (!isCmdPressed) {
    // If Shift key is pressed, pan the stage
    const deltaX = e.deltaX;
    const deltaY = e.deltaY;

    setStagePosition(prevPosition => ({
      x: prevPosition.x - deltaX * 2,
      y: prevPosition.y - deltaY * 2,
    }));

    // Clear previous timer
    clearTimeout(wheelTimer);

    // Clear previous timer
    clearTimeout(wheelTimerRef.current);

    // Set new timer to detect when wheel event is finished
    wheelTimerRef.current = setTimeout(() => {
      // Additional actions to be performed after wheel event is finished
      console.log('setting move scale');
      setMoveStagePosition(stagePosition)
    }, 200); // Adjust the timeout value as needed

    return; // Exit the function early when panning
  }

    // Calculate the position of the mouse pointer relative to the stage
    const mousePointTo = {
      x: (offsetX - stagePosition.x) / oldScale,
      y: (offsetY - stagePosition.y) / oldScale,
    };

    // Apply scaling
    const newScale = e.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
    if(newScale > 2||newScale < 0.15) return;
    
    setScale(newScale);

    // Calculate the new position of the stage based on the mouse pointer
    setStagePosition({
      x: offsetX - mousePointTo.x * newScale,
      y: offsetY - mousePointTo.y * newScale,
    });

    // Clear previous timer
    clearTimeout(wheelTimer);

    // Clear previous timer
    clearTimeout(wheelTimerRef.current);

    // Set new timer to detect when wheel event is finished
    wheelTimerRef.current = setTimeout(() => {
      // Additional actions to be performed after wheel event is finished
      console.log('setting move scale');
      setMoveScale(scale)
    }, 200); // Adjust the timeout value as needed
  }

  //line
  const handleActivateDrawing = event => {
    if (event.target.className === 'Line') {
      setSelectedLine(event.target);
    } else {
      setIsDrawing(true);
      const { x, y } = event.target.getStage().getPointerPosition();
      //updateLines([...lines, { points: [x, y] }]);
    }
  };

  const handleContextMenu = e => {
    console.log('context menu', e)

    e.evt.preventDefault();
    e.evt.stopPropagation();
    //when user click the object
    if(e.target.constructor.name !== e.currentTarget.constructor.name) return;

    const { x, y } = calculatePosition(e);
    setAnchorPosition({x, y})
    setIsContextMenuOpen(true)
  }

  const closeAllContextMenu = () => {
    setIsContextMenuOpen(false);
    setIsObjectContextMenuOpen(false);
  }

  const handleObjectContextMenu = (e, object) => {
    console.log('object context menu', e)
    //closeAllContextMenu();
    e.evt.preventDefault();
    e.evt.stopPropagation();

    const { x, y } = calculatePosition(e);

    setSelectedObject(object);
    setAnchorPosition({x, y});
    setIsObjectContextMenuOpen(true);
  }

  const handleCardContextMenu = (e, activity) => {
    closeAllContextMenu();
    setIsContextMenuOpen(false);
    console.log('e is', e, activity)
    e.preventDefault();
    e.stopPropagation();
    const { x, y } = calculatePosition(e);

    setSelectedObject(activity)
    setAnchorPosition({x, y})
    setIsObjectContextMenuOpen(true)
  }

  const handleResizeEnd = (e, a) => {
    console.log('e is', e)
    const currentWidth = e.clientX;
    //---! rerendering!
    updateActivities(draft => {
      const foundIndex = draft.findIndex(d => d._id === a._id);
      if (foundIndex !== -1) {
        draft[foundIndex].width = currentWidth;
      }
      return draft;
    });

  };

  const handleTextboxOpen = () => {
    setIsContextMenuOpen(false);
    setIsTextboxOpen(true);
  }

  /*
  const handleMouseMove = (event) => {
    if (!isDrawing) return;
    const { x, y } = calculatePosition(event);

    let lastLine = lines[lines.length - 1];
    lastLine.points = lastLine.points.concat([x, y]);

    const newLines = lines.slice(0, lines.length - 1);
    setLines([...newLines, lastLine]);
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  const handleMouseDown = (event) => {
    if (event.target.className === 'Line') {
      setSelectedLine(event.target);
    } else {
      setIsPanEnabled(false)
      setIsDrawing(true);
      const { x, y } = calculatePosition(event);
      //const { x, y } = event.target.getStage().getPointerPosition();
      setLines([...lines, { points: [x, y] }]);
    }
  };
  */
  console.log('restarting...')

  const deleteLine = () => {
    //setLines(lines.filter((line) => line !== selectedLine));
    setSelectedLine(null);
  };

  const handleDeleteObject = () => {
    setIsContextMenuOpen(false);
    setIsObjectContextMenuOpen(false);

    switch (selectedObject.type) {
      case 'COMMENT':
        console.log('selectedObject', selectedObject, activities)
        const newActivities = activities.filter(a => a._id !== selectedObject._id);
        socket.checkAndEmit('DELETE_ACTIVITY', { canvasId: canvasInfo?._id, activityId: selectedObject._id });
        console.log('newActivities', newActivities)
        updateActivities(draft => newActivities);
        break;

      case 'TEXT':
        const newTexts = texts.filter(t => t.id !== selectedObject.id);
        updateTexts(draft => {
        draft = newTexts 
        return draft;
       });
        break;
      default:
    }
  }



  const renderedActivities = useMemo(() => {

    // Assuming you have a function to calculate visibility
    function isElementVisible(elementX, elementY, viewportWidth, viewportHeight, panX, panY, zoom) {
      // Calculate the actual visible area considering pan and zoom
      // This is a simplified example; actual calculation may vary based on your coordinate system and scaling logic
      const visibleLeft = -panX / zoom;
      const visibleTop = -panY / zoom;
      const visibleRight = visibleLeft + viewportWidth / zoom;
      const visibleBottom = visibleTop + viewportHeight / zoom;

      // Check if the element's position is within the visible area
      return elementX >= visibleLeft && elementX <= visibleRight && elementY >= visibleTop && elementY <= visibleBottom;
    }

    let count = 0; 
    return activities.map((a, index, arr) => {
      return (
        <Group
          key={a._id}
          id={index}
          x={a.x} 
          y={a.y}
          draggable
          onDragStart={(e) => {
            console.log('drag started')
            // You can add any additional logic here for drag start
          }}
          onDragEnd={e => handleObjectDragEnd(e, a)}
          
        >
          <Rect x={0} y={-40} fill='#ececec' width={a.action.highlightData.elementWidth} height={40} opacity={hoveredActivityId == a._id? 1 : 0} onMouseOver={e => {console.log('hovered', a._id); setHoveredActivityId(a._id)}} onMouseOut={e => setHoveredActivityId(null)}/>
          <Html>
            <div>
              <div elevation={1} style={{width: a.action.highlightData.elementWidth, resize: 'both', overflow: 'auto'}} onResize={e => handleResizeEnd(e, a)} onContextMenu={e => handleCardContextMenu(e, a)} onMouseOver={e => {setHoveredActivityId(a._id)}} onMouseOut={e => setHoveredActivityId(null)}>
                {
                  a.content[0]?
                    <SimpleContent type='OBJECT' moveScale={moveScale} stageCornerXY={stageCornerXY} isBookmarkLayout={false} content={a.content[0]} action={a.action}/>
                  :
                    null
                }
              </div>
            </div>
          </Html>
        </Group>
    )});
  }, [activities, moveScale, hoveredActivityId, stageCornerXY]); 

  useEffect(() => {
    if(props.canvasData){
        const { canvas, isOwner } = props.canvasData;
        if(!canvas) return updateCanvasInfo(draft => null)
        setLoading(false);
        updateCanvasInfo(draft => {
          return {
            _id: canvas._id, 
            name: canvas.name, 
            accessRestriction: canvas.accessRestriction,
            isOwner,
          }
        });
        setScale(canvas.scale);
        setStagePosition(canvas.stagePosition);
        updateActivities(draft => canvas.activities);
        updateTexts(draft => canvas.texts);
    } else {
      fetch(`${root}/api/canvas/load?person_name=${params.person_name}&canvas_name=${params.canvas_name}`, 
        { 
          method: 'get', 
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          credentials: 'include' 
        }
      )
        .then(res => res.json())
        .then(json => {
          console.log('canvas json is', json)
          setLoading(false);
          const { canvas, isOwner } = json;
          if(!canvas) return updateCanvasInfo(draft => null)
          updateCanvasInfo(draft => {
            return {
              _id: canvas._id, 
              name: canvas.name, 
              accessRestriction: canvas.accessRestriction,
              isOwner,
            }
          });
          setScale(canvas.scale);
          setStagePosition(canvas.stagePosition);
          updateActivities(draft => canvas.activities);
          updateTexts(draft => canvas.texts);
        })
    }
  }, [])

  // useEffect(() => {
  //   // Emit the socket message whenever activities change
  //   if(!canvasInfo) return;
  //   socket.checkAndEmit('UPDATE_ACTIVITIES', { canvasId: canvasInfo?._id, activities: activities });
  // }, [activities]); 

  useEffect(() => {
    if(!canvasInfo) return;
    // Emit the socket message whenever activities change
    socket.checkAndEmit('UPDATE_TEXTS', { canvasId: canvasInfo?._id, texts: texts });
  }, [texts]);

  useEffect(() => {
    if(!canvasInfo) return;
    // Emit the socket message whenever activities change
    socket.checkAndEmit('UPDATE_STAGE_POSITION', { canvasId: canvasInfo?._id, scale, stagePosition });
  }, [moveScale, moveStagePosition]);

  useEffect(() => {
    // Debounce setup
    const debounceDelay = 500; // 0.5 seconds
    const timeoutId = setTimeout(() => {
      const stage = stageRef.current;
      if (!stage) return;
  
      // Calculate the top-left position
      const topLeftX = (0 - stage.x()) / stage.scaleX();
      const topLeftY = (0 - stage.y()) / stage.scaleY();
      console.log("Top-Left Position on Stage:", { x: topLeftX, y: topLeftY });
  
      // Calculate the bottom-right position
      const bottomRightX = (stage.width() - stage.x()) / stage.scaleX();
      const bottomRightY = (stage.height() - stage.y()) / stage.scaleY();
  
      // Update state with debounced values
      setStageCornerXY({ topLeftX, topLeftY, bottomRightX, bottomRightY });
    }, debounceDelay);
  
    // Cleanup function to clear the timeout if the effect runs again before the delay is over
    return () => clearTimeout(timeoutId);
  }, [scale, stagePosition]); // Dependencies

  if(loading){
    return(
      <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', jusitfyContent: 'center'}}>
        <Skeleton width={400} height={600}/>
      </div>
    )
  }


  return (
    <>
      {
        !extensionInstalled
        ?
          null
          //<Extension/>
        :
          null
      }
      <div style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100px',
        marginTop: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 2147480000,
        pointerEvents: 'none',
        /* Optionally, you can add more styling here */
      }}>
        <Typography style={{marginBottom: '2px'}}>
          {'#' + canvasInfo?.name}
        </Typography>
         
          {
            canvasInfo?.isOwner&&user.subscriptionStatus == 'DONOR'
            ?
              <div style={{display: 'flex'}}>
                <FormControlLabel style={{marginLeft: '20px', pointerEvents: 'auto'}} control={<Switch />} onChange={handleSwitchChange} checked={canvasInfo?.accessRestriction == 'PUBLIC'? false : true} label={canvasInfo?.accessRestriction == "PUBLIC"? "PUBLIC": "PRIVATE"} />
              </div>
            :
              null
          }
      </div>
      {
        canvasInfo
        ?
    <div onWheel={handleWheel} style={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      <Stage
        ref={stageRef}
        width={window.innerWidth}
        height={window.innerHeight}
        scaleX={scale}
        scaleY={scale}
        x={stagePosition.x}
        y={stagePosition.y}
        onClick={handleStageClick}
        draggable={isPanEnabled} // Disable stage dragging when dragging rectangle
        onDragEnd={handleStageDragEnd}
        onContextMenu={handleContextMenu}
      >
        <Layer>
          {
            lines.map((line, i) => (
              <Line
                key={i}
                points={line.points}
                stroke={selectedLine === line ? 'red' : 'black'}
                strokeWidth={6}
                onClick={() => setSelectedLine(line)}
              />
            ))
          }
          {renderedActivities}
          {selectedShape && (
          <Transformer
            anchorSize={8}
            borderDash={[6, 2]}
            borderEnabled
            rotateEnabled={true}
            keepRatio={false}
            resizeEnabled={true}
            ref={(node) => {
              if (node && selectedShape) {
                node.nodes([selectedShape]);
              }
            }}
          />
          )}
          {
            texts.map(t => 
              <Text
                key={t.id}
                text={t.text}
                x={t.x}
                y={t.y}
                fontSize={t.fontSize}
                fontFamily={t.fontFamily}
                fill={t.fill}
                draggable
                onDragEnd={e => handleObjectDragEnd(e, t)}
                onContextMenu={e => handleObjectContextMenu(e, t)}
              />
            )
          }
          {
            /*
              <Text
            text="This is a infinite canvas. Drag and move any object. Right click on the canvas to add drawings. Right click on the object to delete it"
            x={0}
            y={0}
            fontSize={20}
            fontFamily="Roboto"
            fill="grey"
            onClick={handleObjectClick}
            draggable
          />
          <Text
            text="100"
            x={100}
            y={0}
            fontSize={20}
            fontFamily="Roboto"
            fill="grey"
            onClick={handleObjectClick}
            draggable
          />
          <Text
            text="200"
            x={200}
            y={0}
            fontSize={20}
            fontFamily="Roboto"
            fill="grey"
            onClick={handleObjectClick}
            draggable
          />
            */
          }
        

          {isContextMenuOpen&&(
            <Group x={anchorPosition.x} y={anchorPosition.y}>
              <Html>
                <div style={{transform: `scale(${1/scale})`, transformOrigin: 'top left', width: '300px'}}>
                  <Paper>
                    <MenuList>
                      <MenuItem onClick={handleTextboxOpen}>
                        <ListItemIcon>
                          <FormatColorTextIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Add a text</ListItemText>
                      </MenuItem>
                      <Divider />
                      <MenuItem>
                        <ListItemIcon>
                          <Cloud fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Drawing features will be added soon!</ListItemText>
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </div>
              </Html>
            </Group>
          )}
          {isObjectContextMenuOpen&&(
            <Group x={anchorPosition.x} y={anchorPosition.y}>
              <Html>
                <div style={{transform: `scale(${1/scale})`, transformOrigin: 'top left', width: '300px'}}>
                  <Paper>
                    <MenuList>
                      <MenuItem onClick={handleDeleteObject}>
                        <ListItemIcon>
                          <DeleteForeverIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Delete object</ListItemText>
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </div>
              </Html>
            </Group>
          )}
          {isCardContextMenuOpen&&(
            <Group x={anchorPosition.x} y={anchorPosition.y}>
              <Html>
                <div style={{transform: `scale(${1/scale})`, transformOrigin: 'top left', width: '300px'}}>
                  <Paper>
                    <MenuList>
                      <MenuItem onClick={handleDeleteObject}>
                        <ListItemIcon>
                          <DeleteForeverIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText>Delete object</ListItemText>
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </div>
              </Html>
            </Group>
          )}
          {
            /*
              <BeizerCurve/>
              <EditableLine/>
            */
          }
          {
            isTextboxOpen
            ?
              <Textbox x={anchorPosition.x} y={anchorPosition.y} scale={scale} setIsTextboxOpen={setIsTextboxOpen} texts={texts} updateTexts={updateTexts}/>
            :
              null  
          }       
          </Layer>
      </Stage>
    </div>
      :
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Typography variant='h1' style={{marginTop: '300px'}}>Sorry, we cannot find the canvas</Typography>
      </div>
    }
    </>
  );
};

export default InfiniteCanvas;